import { useContext, BaseSyntheticEvent, useState, useEffect } from "react";
import { ManagerViewContext, ManagerViewContextType } from "../../../../context";
import ProfileImageSkeleton from "../../../../../skeletons/ProfileImageSkeleton";
import { SubordinateInterface, SubordinateVacationInterface } from "../../../../store";
import { observer } from "mobx-react-lite";
import dayjs from "dayjs";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import LoopIcon from "@mui/icons-material/Loop";
import { Dialog, DialogContent, TextField, DialogActions, Button } from "@mui/material";
import AuthContext, { AuthContextType } from "../../../../../../context/AuthContext";
import { MyCalendarContext, MyCalendarContextType } from "../../../../context";
import { motion } from "framer-motion";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VacationTrack from "../../../VacationTrack";

interface SubordinateCardInterface {
    subordinate: SubordinateInterface;
    allUsersExpanded: boolean;
}

interface VacationPropsInterface {
    vacation: SubordinateVacationInterface;
}

interface VacationBlockInterface {
    vacation: SubordinateVacationInterface;
    subordinate: SubordinateInterface;
}

const StatusBubble = observer(({ vacation }: VacationPropsInterface) => {
    const [trackOpen, setTrackOpen] = useState<boolean>(false)
    let text: string = "";
    let color: string = "";
    if (vacation.status.name === "moderate_employees_manager") {
        if (vacation.approve_set.filter((el) => el.approved === false).length) {
            text = "Отказ";
            color = "bg-red-600";
        } else {
            text = "Согласование";
            color = "bg-yellow-500";
        }
    }

    const handleTrackOpen = (e: BaseSyntheticEvent) => {
        setTrackOpen(true)
    }

    const handleTrackClose = (e: BaseSyntheticEvent) => {
        setTrackOpen(false)
    }

    return (
        <div className="flex items-center gap-2" onClick={(e: BaseSyntheticEvent) => e.stopPropagation()}>
            <button
                className={`rounded px-1 ${vacation.type.name === "planned" ? "bg-purple-500" : "bg-blue-500"}`}
                title={vacation.type.name === "planned" ? "Планируемый" : "Очередной"}
            >
                {vacation.type.name === "planned" ? (
                    <KeyboardDoubleArrowRightIcon fontSize="small" />
                ) : (
                    <LoopIcon fontSize="small" />
                )}
            </button>
            <div>
                <button className={`font-semibold ${color} rounded px-1 w-fit`} onClick={handleTrackOpen}>{text}</button>
                <VacationTrack open={trackOpen} onClose={handleTrackClose} vacation={vacation}/>
            </div>
        </div>
    );
});

const ButtonsBlock = observer(({ vacation }: VacationPropsInterface) => {
    const { myCalendarStore } = useContext(MyCalendarContext) as MyCalendarContextType;
    const { domainStore } = useContext(AuthContext) as AuthContextType;
    const [declineOpen, setDeclineOpen] = useState<boolean>(false);
    const [refuseReason, setRefuseReason] = useState<string>("");

    const handleDeclineOpen = (e: BaseSyntheticEvent) => {
        setDeclineOpen(true);
    };

    const handleDeclineClose = () => {
        setDeclineOpen(false);
    };

    const onRefuseChange = (e: BaseSyntheticEvent) => {
        setRefuseReason(e.target.value);
    };

    const submitRefuse = (e: BaseSyntheticEvent) => {
        e.preventDefault();
        myCalendarStore.refuseVacation(vacation.id, refuseReason);
        handleDeclineClose();
    };

    return (
        <div onClick={(e: BaseSyntheticEvent) => e.stopPropagation()}>
            {!!vacation.approve_set.filter(
                (el) =>
                    el.approved === null &&
                    el.approve_user.id === domainStore.user.id &&
                    el.status.name === "moderate_employees_manager"
            ).length && (
                <div className="flex items-center gap-3">
                    <button title="Согласовать" className="action-btn bg-green-500">
                        <CheckIcon />
                    </button>
                    <button title="Отклонить" className="action-btn bg-red-500" onClick={handleDeclineOpen}>
                        <CloseIcon />
                    </button>
                </div>
            )}
            <Dialog
                disableRestoreFocus
                open={declineOpen}
                onClose={handleDeclineClose}
                PaperProps={{ component: "form", onSubmit: submitRefuse }}
                fullWidth
                maxWidth="sm"
            >
                <DialogContent>
                    <div className="mb-2">Опишите причину отказа:</div>
                    <TextField multiline rows={3} autoFocus value={refuseReason} onChange={onRefuseChange} required  fullWidth/>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={handleDeclineClose}>
                        Отмена
                    </Button>
                    <Button color="signature" type="submit" variant="contained">
                        Подтвердить
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
});

const VacationBlock = observer(({ vacation, subordinate }: VacationBlockInterface) => {
    const { myCalendarStore } = useContext(MyCalendarContext) as MyCalendarContextType;
    const { setVacationObjectId } = useContext(ManagerViewContext) as ManagerViewContextType;
    const dateFormat = "DD.MM.YYYY";
    const handleVacationClick = (vacationId: number) => {
        setVacationObjectId(vacationId);
    };

    return (
        <div
            className="grid grid-cols-12 rounded-card cursor-pointer gap-y-2"
            data-tag={vacation.date_start}
            onClick={() => handleVacationClick(vacation.id)}
        >
            <div className="col-span-2 vacation-item">
                <div className="dark:dark-text-secondary">Дней</div>
                <div className="font-semibold">
                    {dayjs(vacation.date_end).diff(dayjs(vacation.date_start), "days", true) + 1}
                </div>
            </div>
            <div className="col-span-6 vacation-item">
                <div className="dark:dark-text-secondary">Период</div>
                <div className="font-semibold">
                    {dayjs(vacation.date_start).format(dateFormat)} - {dayjs(vacation.date_end).format(dateFormat)}
                </div>
            </div>
            <div className="col-span-4 vacation-item flex flex-col items-end">
                <StatusBubble vacation={vacation} />
                <ButtonsBlock vacation={vacation} />
            </div>
            {!!vacation.intersection_list.length && (
                <div className="col-span-12">
                    <span className="italic text-xs sm:text-sm text-red-600 dark:text-yellow-300">
                        Пересечение с: {vacation.intersection_list.join(", ")}
                    </span>
                </div>
            )}
        </div>
    );
});

const SubordinateCard = ({ subordinate, allUsersExpanded }: SubordinateCardInterface) => {
    const [cardExpanded, setCardExpanded] = useState<boolean>(allUsersExpanded);
    const handleExpandChange = () => {
        setCardExpanded((pv) => !pv);
    };
    const variants = {
        collapsed: {
            opacity: 0,
            height: 0,
        },
        expanded: {
            opacity: 1,
            height: "auto",
        },
    };

    useEffect(() => {
        setCardExpanded(allUsersExpanded);
    }, [allUsersExpanded]);

    return (
        <div className="p-1 sm:p-3 bg-white dark:dark-surface-400 rounded-md">
            <div className="flex items-center justify-between w-full flex-wrap">
                <div className="flex gap-2 items-center">
                    <ProfileImageSkeleton
                        image={subordinate.profile.thumbnail}
                        className="w-[30px] text-[30px] md:w-[35px] md:text-[35px]"
                    />
                    <span className="text-sm font-semibold">
                        {subordinate.last_name} {subordinate.first_name}
                    </span>
                </div>
                <button className="flex items-center" onClick={handleExpandChange}>
                    {cardExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </button>
            </div>
            <motion.div
                initial={false}
                animate={cardExpanded ? "expanded" : "collapsed"}
                variants={variants}
                className="overflow-hidden"
            >
                {subordinate.vacations.length ? (
                    <div className="flex flex-col gap-2 mt-1 sm:mt-2">
                        {subordinate.vacations.map((el, idx) => (
                            <VacationBlock key={idx} vacation={el} subordinate={subordinate} />
                        ))}
                    </div>
                ) : (
                    <div className="rounded-card flex justify-center items-center font-semibold my-1 sm:mt-2">
                        Отпусков нет
                    </div>
                )}
            </motion.div>
        </div>
    );
};

export default observer(SubordinateCard);
