import ViewNewsStore from "../../store";
import { observer } from "mobx-react-lite";
import { motion, useMotionValue, AnimatePresence } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { getFileFormat } from "../../../common/utils";
import dayjs from "dayjs";

const TRANSITION_OPTIONS = {
    type: "spring",
    bounce: 0,
};

interface DraggableCarouselInterface {
    store: ViewNewsStore;
}

const MobileDraggableCarousel = ({ store }: DraggableCarouselInterface) => {
    const DRAG_THRESHOLD = 40;
    const [dragging, setDragging] = useState(false);
    const dragX = useMotionValue(0);

    const onDragStart = () => {
        setDragging(true);
    };

    const onDragEnd = () => {
        setDragging(false);

        const x = dragX.get();
        if (x <= -DRAG_THRESHOLD && store.carouselIdx < store.imageList.length - 1) {
            store.setCarouselIdx(store.carouselIdx + 1);
        } else if (x >= DRAG_THRESHOLD && store.carouselIdx > 0) {
            store.setCarouselIdx(store.carouselIdx - 1);
        }
    };

    return (
        <div>
            <motion.div
                drag="x"
                dragConstraints={{ left: 0, right: 0 }}
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                animate={{ translateX: `-${store.carouselIdx * 100}%` }}
                style={{ x: dragX }}
                className="w-full flex cursor-grab items-center"
                dragElastic={{
                    left: store.carouselIdx === store.imageList.length - 1 ? 0.3 : 1,
                    right: store.carouselIdx === 0 ? 0.3 : 1,
                }}
                transition={TRANSITION_OPTIONS}
            >
                <Media store={store} />
            </motion.div>
            <div className="w-full flex justify-center mt-2">
                <Dots store={store} />
            </div>
            <MyModal store={store} />
        </div>
    );
};

const Media = observer(({ store }: DraggableCarouselInterface) => {
    return (
        <>
            {store.imageList.map((el, idx) => (
                <motion.div
                    key={el.image}
                    initial={false}
                    animate={{ scale: idx === store.carouselIdx ? 1 : 0.9 }}
                    transition={{ ease: "easeOut" }}
                    className={`overflow-hidden flex justify-center relative hover:cursor-pointer shrink-0 w-full rounded-xl aspect-video ${
                        !el.loaded && "animate-pulse bg-slate-200 cursor-wait"
                    }`}
                    onClick={() => {
                        store.setCurrImageIdx(store.carouselIdx);
                        store.setMobileModalOpen(true);
                    }}
                >
                    {el.loaded && <BlurredImageBack src={el.image} />}
                    {el.loaded && (
                        <div className="w-full h-full flex justify-center absolute z-10">
                            <MediaItem src={el.image} />
                        </div>
                    )}
                </motion.div>
            ))}
        </>
    );
});

const BlurredImageBack = ({ src }: { src: string }) => {
    const parsedFile = getFileFormat(src);
    if (parsedFile.format === "image") {
        return <img src={src} className="absolute object-cover w-full h-full blur-lg z-0" />;
    }
    return null;
};

const MediaItem = ({ src }: { src: string }) => {
    const parsedFile = getFileFormat(src);
    const videoRef = useRef<HTMLVideoElement>(null);
    const [duration, setDuration] = useState<any>(null);

    if (parsedFile.format === "video") {
        return (
            <div className="w-full h-full flex justify-center bg-slate-200 dark:dark-surface-500 relative">
                <video
                    ref={videoRef}
                    src={src + "#t=0.001"}
                    playsInline
                    onLoadedMetadata={() => setDuration(videoRef.current?.duration)}
                />
                {videoRef.current && duration && (
                    <div className="absolute left-2 bottom-2 text-white bg-black/70 rounded-md px-1 py-0.5">
                        {dayjs.duration(duration * 1000).format("mm:ss")}
                    </div>
                )}
            </div>
        );
    }
    return <img src={src} />;
};

const Dots = observer(({ store }: DraggableCarouselInterface) => {
    return (
        <div className="flex gap-2 bg-black/40 rounded-xl px-2 py-1">
            {store.imageList.map((el, idx) => (
                <button
                    key={idx}
                    onClick={() => store.setCarouselIdx(idx)}
                    className={`w-2 h-2 rounded-full ${idx === store.carouselIdx ? "bg-neutral-50" : "bg-neutral-500"}`}
                />
            ))}
        </div>
    );
});

const MyModal = observer(({ store }: DraggableCarouselInterface) => {
    const DRAG_THRESHOLD = 50;
    const DRAG_Y_THRESHOLD = 100;
    const [draggingX, setDraggingX] = useState(false);
    const [draggingY, setDraggingY] = useState(false);
    const dragXModal = useMotionValue(0);
    const dragYModal = useMotionValue(0);

    const onDragStartX = () => {
        setDraggingX(true);
    };
    const onDragEndX = () => {
        setDraggingX(false);

        const x = dragXModal.get();
        if (x <= -DRAG_THRESHOLD && store.currImageIdx < store.imageList.length - 1) {
            store.setCurrImageIdx(store.currImageIdx + 1);
        } else if (x >= DRAG_THRESHOLD && store.currImageIdx > 0) {
            store.setCurrImageIdx(store.currImageIdx - 1);
        }
    };

    const onDragStartY = () => {
        setDraggingY(true);
    };

    const onDragEndY = () => {
        setDraggingY(false);

        const y = dragYModal.get();
        if (y <= -DRAG_Y_THRESHOLD || y >= DRAG_Y_THRESHOLD) {
            store.setMobileModalOpen(false);
        }
    };

    // Если не центрировать, то при повторном открытии модалки блок съедет
    useEffect(() => {
        if (store.mobileModalOpen) {
            dragYModal.set(0);
            dragXModal.set(0);
        }
    }, [store.mobileModalOpen]);

    return (
        <AnimatePresence>
            {store.mobileModalOpen && (
                <motion.div
                    key="modal"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="fixed h-screen w-screen z-50 bg-black/70 left-0 top-0 overflow-hidden"
                >
                    <motion.div
                        drag={"y"}
                        dragDirectionLock
                        dragConstraints={{ top: 0, bottom: 0 }}
                        className="w-screen relative h-screen "
                        style={{ y: dragYModal }}
                        onDragStart={onDragStartY}
                        onDragEnd={onDragEndY}
                        dragElastic={1}
                        initial={{ translateY: 0 }}
                    >
                        <motion.div
                            drag={"x"}
                            dragDirectionLock
                            initial={false}
                            dragConstraints={{ left: 0, right: 0 }}
                            className="absolute flex h-screen items-center"
                            onDragStart={onDragStartX}
                            onDragEnd={onDragEndX}
                            animate={{ translateX: `-${store.currImageIdx * 100}vw` }}
                            style={{ x: dragXModal }}
                            dragElastic={{
                                left: store.currImageIdx === store.imageList.length - 1 ? 0.3 : 1,
                                right: store.currImageIdx === 0 ? 0.3 : 1,
                            }}
                            transition={TRANSITION_OPTIONS}
                        >
                            {store.imageList.map((el, idx) => (
                                <div className="w-screen flex justify-center" key={idx}>
                                    <ModalMediaItem src={el.image} idx={idx} store={store} key={idx} />
                                </div>
                            ))}
                        </motion.div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
});

const ModalMediaItem = observer(({ src, idx, store }: { src: string; idx: number; store: ViewNewsStore }) => {
    const parsedFile = getFileFormat(src);
    const videoRef = useRef<HTMLVideoElement>(null);

    const pauseVideo = (vidReference: HTMLVideoElement) => {
        vidReference.pause();
        vidReference.currentTime = 0;
    };

    const handleException = (e: any) => {
        if (e.name === "AbortError") {
            return;
        }
        throw e;
    };

    useEffect(() => {
        if (videoRef.current) {
            setTimeout(() => {
                if (videoRef.current) {
                    if (store.currImageIdx === idx) {
                        videoRef.current.play().catch((e) => handleException(e));
                    } else {
                        pauseVideo(videoRef.current);
                    }
                }
            }, 300);
        }
    }, [store.currImageIdx]);

    if (!store.imageList[idx].loaded) {
        return <div className="bg-slate-200 animate-pulse w-[90vw] h-[40vh] rounded-md" />;
    }

    if (parsedFile.format === "video") {
        return (
            <motion.video
                ref={videoRef}
                className="max-h-[90vh]"
                initial={false}
                animate={{ scale: idx === store.currImageIdx ? 1 : 0.9 }}
                transition={{ ease: "easeOut" }}
                key={idx}
                src={src}
                controls
                loop
                autoPlay={store.currImageIdx === idx ? true : false}
                playsInline
            />
        );
    }
    return (
        <motion.img
            className="max-h-[80vh]"
            initial={false}
            animate={{ scale: idx === store.currImageIdx ? 1 : 0.9 }}
            transition={{ ease: "easeOut" }}
            key={idx}
            src={src}
        />
    );
});

export default observer(MobileDraggableCarousel);
