import "./styles.css";
import BirthdayBlock from "./components/BirthdayBlock";
import RecentEmployeesBlock from "./components/RecentEmployeesBlock";
import CelebrationIcon from "@mui/icons-material/Celebration";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import { BaseSyntheticEvent, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

type TabType = "" | "birthday" | "newUsers";

interface MobileWidgetViewInterface {
    tab: TabType;
}

const MobileWidgetView = ({ tab }: MobileWidgetViewInterface) => {
    if (tab === "birthday") {
        return <BirthdayBlock />;
    } else if (tab === "newUsers") {
        return <RecentEmployeesBlock />;
    }
    return null;
};

const TempWidgetBlock = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("lg"));
    const [mobile, setMobile] = useState<boolean>(!matches);
    const [tab, setTab] = useState<TabType>("");

    const handleTabClick = (e: BaseSyntheticEvent, name: TabType) => {
        if (tab === name) {
            setTab("");
        } else {
            setTab(name);
        }
    };

    useEffect(() => {
        setMobile(!matches);
    }, [matches]);

    return (
        <div id="widgetBlock">
            <div className="flex lg:grid lg:grid-cols-1 2xl:grid-cols-2 widget-wrapper gap-3 lg:gap-8">
                {mobile ? (
                    <button
                        className={`transition ${tab === "birthday" ? "dark-primary-400" : "dark-surface-300"}`}
                        onClick={(e) => handleTabClick(e, "birthday")}
                    >
                        <CelebrationIcon />
                    </button>
                ) : (
                    <div className="h-full">
                        <BirthdayBlock />
                    </div>
                )}

                {mobile ? (
                    <button
                        className={`transition ${tab === "newUsers" ? "dark-primary-400" : "dark-surface-300"}`}
                        onClick={(e) => handleTabClick(e, "newUsers")}
                    >
                        <AccessibilityNewIcon />
                    </button>
                ) : (
                    <div className="h-full">
                        <RecentEmployeesBlock />
                    </div>
                )}
            </div>

            {mobile && (
                <motion.div
                    className="mobile overflow-hidden"
                    animate={{ height: !tab ? "0px" : "auto" }}
                    transition={{ ease: "easeOut" }}
                    initial={false}
                >
                    <div className="mt-3">
                        <MobileWidgetView tab={tab} />
                    </div>
                </motion.div>
            )}
        </div>
    );
};

export default TempWidgetBlock;
