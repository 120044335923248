import { motion } from "framer-motion";
import { useContext } from "react";
import AuthContext, { AuthContextType } from "../../context/AuthContext";
import { observer } from "mobx-react-lite";

interface SlideCirclesInterface {
    slide: number;
    newsLength: number;
    moveToSlide: Function;
}

const SlideCircles = ({ slide, newsLength, moveToSlide }: SlideCirclesInterface) => {
    const { domainStore } = useContext(AuthContext) as AuthContextType;
    const lst = [];
    const variants = {
        active: {
            backgroundColor: domainStore.theme === "light" ? "#ab2121" : "#7A5AF5",
        },
        inactive: {
            backgroundColor: domainStore.theme === "light" ? "#F4F4F6" : "#e5e7eb",
        },
    };
    for (let i = 0; i < newsLength; i++) {
        lst.push(i);
    }

    return (
        <div className="bg-black/40 rounded-full px-3 py-2 flex gap-2">
            {lst.map((el) => (
                <motion.div
                    key={el}
                    variants={variants}
                    animate={el === slide ? "active" : "inactive"}
                    initial={el === slide ? "active" : "inactive"}
                    className="rounded-full size-2.5 2xl:size-3 cursor-pointer"
                    onClick={() => moveToSlide(el)}
                />
            ))}
        </div>
    );
};

export default observer(SlideCircles);
