import { useState, useEffect, useRef } from "react";
import WidgetLayout from "../../layouts/WidgetLayout";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { motion } from "framer-motion";
import { NewsItemInterface } from "./interfaces";
import NewsCard from "./NewsCard";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import OutboundIcon from "@mui/icons-material/Outbound";
import { useNavigate } from "react-router-dom";
import HomeNewsStore from "./store";
import { observer } from "mobx-react-lite";
import SlideCircles from "./SlideCircles";
import TagsBlock from "./components/TagsBlock";
import "./styles.css";

const HomePage = () => {
    const [store] = useState(() => new HomeNewsStore());
    const tagRef = useRef<HTMLButtonElement>(null);

    const navigate = useNavigate();
    const variants = {
        mainBlock: {
            simple: {
                borderRadius: "40px 40px 40px 40px",
            },
            expanded: {
                borderRadius: "40px 40px 0px 40px",
            },
        },
        tagsBlock: {
            simple: {
                rotate: "90deg",
            },
            expanded: {
                rotate: "0deg",
            },
        },
    };
    const whileTap = {
        scale: 0.9,
    };

    useEffect(() => {
        async function fetchNews() {
            await store.fetchNews();
            await store.fetchTags();
        }
        fetchNews();
    }, []);

    const nextSlide = () => {
        if (store.slide === store.news.length - 1) {
            store.setSlide(0);
        } else {
            store.setSlide(store.slide + 1);
        }
    };

    const prevSlide = () => {
        if (store.slide === 0) {
            store.setSlide(store.news.length - 1);
        } else {
            store.setSlide(store.slide - 1);
        }
    };

    const moveToSlide = (slide: number) => {
        store.setSlide(slide);
    };

    const newsCarousel = () => {
        if (store.loadingNews) {
            return <div className="h-full loading-color general-card overflow-hidden" />;
        } else if (store.news.length) {
            return (
                <motion.div
                    className="flex absolute w-full h-full"
                    animate={{ translateX: `-${store.slide * 100}%` }}
                    transition={{ ease: "easeOut" }}
                >
                    {store.news.map((newsItem: NewsItemInterface, idx: number) => (
                        <NewsCard slide={store.slide} idx={idx} newsItem={newsItem} key={newsItem.id} store={store} />
                    ))}
                </motion.div>
            );
        } else {
            return (
                <div className="h-full bg-white dark:dark-surface-300 flex items-center justify-center general-card text-xl 2xl:text-3xl font-semibold p-10 2xl:p-32 text-center">
                    По вашему запросу не найдено новостей
                </div>
            );
        }
    };

    return (
        <WidgetLayout>
            <div className="flex justify-between align-top flex-wrap gap-2 mb-3 sm:mb-5 2xl:mb-7">
                <div className="avx-page-h">Новости</div>
                <motion.div
                    className="bg-white dark:dark-surface-300 flex py-1 px-2 2xl:px-4"
                    variants={variants.mainBlock}
                    animate={store.tagsBlockExpanded ? "expanded" : "simple"}
                    transition={{ ease: "easeOut" }}
                    initial={variants.mainBlock.simple}
                >
                    <div
                        className="border-r-2 dark:border-[#717171] flex items-center justify-between cursor-pointer gap-2 pe-2"
                        onClick={() => navigate("/news/create")}
                    >
                        <div className="text-nowrap text-xs sm:text-sm lg:text-base">Предложить новость</div>
                        <div>
                            <AddCircleIcon />
                        </div>
                    </div>
                    <div className="basis-full flex items-center relative">
                        <button
                            ref={tagRef}
                            className="basis-full flex items-center justify-between cursor-pointer ps-3 gap-2"
                            onClick={(e) => {
                                e.stopPropagation();
                                store.setTagsBlockExpanded(!store.tagsBlockExpanded);
                            }}
                        >
                            <div className="text-nowrap text-xs sm:text-sm lg:text-base">Найти новость</div>
                            <motion.div
                                animate={store.tagsBlockExpanded ? "expanded" : "simple"}
                                variants={variants.tagsBlock}
                                transition={{ ease: "easeOut" }}
                                initial={false}
                                className="flex items-center justify-center"
                            >
                                <OutboundIcon />
                            </motion.div>
                        </button>
                        <TagsBlock store={store} triggerRef={tagRef} />
                    </div>
                </motion.div>
            </div>
            <div className="relative h-[470px] sm:h-[600px] lg:h-[810px]">
                <div className="absolute w-full h-full">
                    {store.news.length > 0 && !store.loadingNews && (
                        <motion.div
                            whileTap={whileTap}
                            onClick={prevSlide}
                            className="change-slide-arr -left-2 sm:-left-3 2xl:-left-7"
                            title="Назад"
                        >
                            <ArrowBackIosNewIcon className="!text-base sm:!text-xl 2xl:!text-3xl" />
                        </motion.div>
                    )}
                    {store.news.length > 0 && !store.loadingNews && (
                        <motion.div
                            whileTap={whileTap}
                            onClick={nextSlide}
                            className="change-slide-arr -right-2 sm:-right-3 2xl:-right-7"
                            title="Вперёд"
                        >
                            <ArrowForwardIosIcon className="!text-base sm:!text-xl 2xl:!text-3xl" />
                        </motion.div>
                    )}
                </div>
                <div className="h-full w-full overflow-x-hidden relative">
                    {store.news.length > 0 && (
                        <div className="absolute z-20 w-full flex justify-center top-3">
                            <SlideCircles
                                slide={store.slide}
                                newsLength={store.news.length}
                                moveToSlide={moveToSlide}
                            />
                        </div>
                    )}
                    {newsCarousel()}
                </div>
            </div>
        </WidgetLayout>
    );
};

export default observer(HomePage);
