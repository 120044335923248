import { observer } from "mobx-react-lite";
import { useState, useContext, BaseSyntheticEvent, ReactNode, MouseEvent } from "react";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { VacationItemInterface } from "../../../../store";
import { MyCalendarContext, MyCalendarContextType } from "../../../../context";
import "./styles.css";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CancelIcon from "@mui/icons-material/Cancel";
import VacationTrack from "../../../VacationTrack";

const StatusIcon = ({ vacation }: { vacation: VacationItemInterface }) => {
    const [open, setOpen] = useState<boolean>(false);

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setOpen(true);
    };

    const handleClose = (event: BaseSyntheticEvent) => {
        event.stopPropagation();
        setOpen(false);
    };

    let icon: ReactNode = null;
    if (vacation.status === "moderate_employees_manager") {
        if (vacation.approve_set?.filter((el) => el.approved === false).length) {
            icon = (
                <div className="text-red-500">
                    <CancelIcon />
                </div>
            );
        } else {
            icon = (
                <div className="text-yellow-300">
                    <AccessTimeIcon />
                </div>
            );
        }
    }
    return (
        <div>
            <button onClick={handleClick} title="Отслеживание статуса отпуска">{icon}</button>
            <VacationTrack open={open} onClose={handleClose} vacation={vacation} />
        </div>
    );
};

const VacationBubble = ({ vacation }: { vacation: VacationItemInterface }) => {
    const { myCalendarStore } = useContext(MyCalendarContext) as MyCalendarContextType;
    const vacationDict = {
        planVacation: "Запланированный отпуск",
        createVacation: "Очередной отпуск",
    };
    const dateFormat = "DD.MM.YYYY";

    const handleBubbleClick = () => {
        myCalendarStore.setScrollDate(vacation.dateStart);
    };

    const handleVacationRemove = (e: BaseSyntheticEvent) => {
        e.stopPropagation();
        myCalendarStore.setRemainingPlanDays(myCalendarStore.remainingPlanDays + vacation.usedDays);
        myCalendarStore.setVacationList(myCalendarStore.vacationList.filter((el) => el !== vacation));
    };

    return (
        <div className="cursor-pointer shrink-0 vacation-bubble p-1.5 rounded-md" onClick={handleBubbleClick}>
            <div className="mb-1 flex items-center justify-between">
                <div className="text-nowrap rounded-md text-xs p-0.5 bg-[#0627EE] text-white dark:dark-primary-600 w-fit">
                    {vacationDict[vacation.type]}
                </div>
                <div className="flex items-center gap-1">
                    {vacation.status === "draft" || myCalendarStore.canRemovePlanned ? (
                        <div title="Удалить">
                            <DeleteIcon onClick={handleVacationRemove} />
                        </div>
                    ) : (
                        <></>
                    )}
                    {vacation.status !== "draft" && <StatusIcon vacation={vacation} />}
                </div>
            </div>
            <div>
                <div className="info-row">
                    <span className="title">Количество дней:</span>
                    <div className="flex">
                        {vacation.dateEnd.diff(vacation.dateStart, "day", true) + 1}
                        {vacation.usedDays !== vacation.dateEnd.diff(vacation.dateStart, "day") + 1 && (
                            <span className="ml-0.5">({vacation.usedDays})</span>
                        )}
                    </div>
                </div>
                <div className="info-row">
                    <span className="title">Период:</span>
                    <span className="text-nowrap">
                        {vacation.dateStart.format(dateFormat)} - {vacation.dateEnd.format(dateFormat)}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default observer(VacationBubble);
