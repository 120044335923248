import BirthdayImage from "../BirthdayImage";
import Store from "./store";
import { useEffect, useState } from "react";
import UsersBlock from "../UsersBlock";

const BirthdayBlock = () => {
    const [store] = useState<Store>(() => new Store());

    useEffect(() => {
        const fetchUsers = async () => {
            await store.fetchUsers();
        };
        fetchUsers();
    }, []);

    return (
        <div className="general-widget-inner">
            <div>
                <h1>Дни рождения</h1>
                <div>
                    <BirthdayImage />
                </div>
            </div>
            <div>
                <UsersBlock store={store} loadingElementsNum={4}/>
            </div>
        </div>
    );
};

export default BirthdayBlock;
