import SurfingIcon from "@mui/icons-material/Surfing";
import "./styles.css";
import { BaseSyntheticEvent, ReactNode } from "react";
import { motion } from "framer-motion";
import PathWrapper from "./components/PathWrapper";
import { useNavigate } from "react-router-dom";

interface OptionInterface {
    name: string;
    icon: ReactNode;
    link: string;
}

const Admin = () => {
    const navigate = useNavigate();
    const options: Array<OptionInterface> = [{ name: "Отпуска", icon: <SurfingIcon />, link: "vacation" }];

    const navigateTo = (e: BaseSyntheticEvent) => {
        e.preventDefault();
        navigate(e.target.dataset.link);
    };

    return (
        <PathWrapper>
            <div className="grid grid-cols-1">
                {options.map((el, idx) => (
                    <motion.a
                        key={idx}
                        className="option-bubble flex gap-1"
                        onClick={navigateTo}
                        href={"/admin/" + el.link}
                        data-link={el.link}
                    >
                        {el.icon}
                        {el.name}
                    </motion.a>
                ))}
            </div>
        </PathWrapper>
    );
};

export default Admin;
