import { makeAutoObservable, reaction } from "mobx";
import { enqueueSnackbar } from "notistack";
import NewsService from "../../services/NewsService";

interface SafeUserInterface {
    id: number;
    first_name: string;
    last_name: string;
}

export interface TagInterface {
    id: number;
    name: string;
    color: string;
}

export default interface NewsInterface {
    id: number;
    title: string;
    text: string;
    title_image: string;
    published_at: string;
    author: SafeUserInterface;
    likes: number;
    liked: boolean;
    comments: number;
}

export default class HomeNewsStore {
    news: Array<NewsInterface> = [];
    loadingNews: boolean = true;
    slide: number = 0;

    tagsFilter: Array<number> = [];
    tags: Array<TagInterface> = [];
    loadingTags: boolean = true;
    tagsBlockExpanded: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    setTagsBlockExpanded = (bool: boolean) => {
        this.tagsBlockExpanded = bool;
    };

    setNews = (news: Array<NewsInterface>) => {
        this.news = news;
    };

    setSlide = (slide: number) => {
        this.slide = slide;
    };

    setNewsLoading = (bool: boolean) => {
        this.loadingNews = bool;
    };

    setTags = (tags: Array<TagInterface>) => {
        this.tags = tags;
    };

    setTagsLoading = (bool: boolean) => {
        this.loadingTags = bool;
    };

    swapTagsFilter = (id: number) => {
        if (this.tagsFilter.includes(id)) {
            this.tagsFilter = this.tagsFilter.filter((el) => el !== id);
        } else {
            this.tagsFilter = [...this.tagsFilter, id];
        }
    };

    swapLike = async (id: number) => {
        try {
            const res = await NewsService.swapLike(id);
            let changedNews: Array<NewsInterface> = [];

            for (const el of this.news) {
                let copiedEl = { ...el };
                if (copiedEl.id === id) {
                    copiedEl.liked = res.data.liked;
                    if (res.data.liked) {
                        copiedEl.likes++;
                    } else {
                        copiedEl.likes--;
                    }
                }
                changedNews.push(copiedEl);
            }
            this.setNews(changedNews);
        } catch (e) {
            console.error(e);
            enqueueSnackbar("Ошибка");
        }
    };

    fetchNews = async () => {
        setTimeout(async () => {
            try {
                const news = await NewsService.getPublishedNews(1, 10, "", this.tagsFilter);
                this.setNews(news.data.results);
            } catch (e) {
                console.error(e);
                enqueueSnackbar("Ошибка запроса новостей");
            } finally {
                this.setNewsLoading(false);
            }
        }, 0);
    };

    fetchTags = async () => {
        setTimeout(async () => {
            try {
                const tags = await NewsService.getTags();
                this.setTags(tags.data);
            } catch (e) {
                console.error(e);
                enqueueSnackbar("Ошибка запроса тегов");
            } finally {
                this.setTagsLoading(false);
            }
        }, 0);
    };
}
