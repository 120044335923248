import React from "react";
import MainLayout from "../MainLayout";
import WidgetLayoutContext from "./WidgetLayoutContext";
import { motion } from "framer-motion";
import TempWidgetBlock from "./components/TempWidgetBlock";

interface LayoutProps {
    children?: React.ReactNode;
}

const WidgetLayout = ({ children }: LayoutProps) => {
    const mainContentHeight = 880;
    const widgetsGap = 16;

    return (
        <MainLayout>
            <WidgetLayoutContext.Provider value={{ mainContentHeight, widgetsGap }}>
                <motion.div className="grid grid-cols-1 gap-y-4 lg:grid-cols-2 lg:gap-x-10 2xl:gap-x-16">
                    <div className="order-last lg:order-first">{children}</div>
                    <div className="">
                        <TempWidgetBlock />
                    </div>
                </motion.div>
            </WidgetLayoutContext.Provider>
        </MainLayout>
    );
};

export default WidgetLayout;
