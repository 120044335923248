import HomeNewsStore from "../store";
import { motion } from "framer-motion";
import { observer } from "mobx-react-lite";
import { ClickAwayListener } from "@mui/material";
import { RefObject, useContext } from "react";
import AuthContext, { AuthContextType } from "../../../context/AuthContext";
import "./styles.css"

interface TagsBlockInterface {
    store: HomeNewsStore;
    triggerRef: RefObject<HTMLButtonElement>;
}

const TagsBlock = ({ store, triggerRef }: TagsBlockInterface) => {
    const { domainStore } = useContext(AuthContext) as AuthContextType;
    const variants = {
        tagsBlock: {
            simple: { height: "0px", overflow: "hidden" },
            expanded: {
                height: "auto",
            },
        },
        tagsItem: {
            simple: {
                opacity: 0.7,
            },
            selected: {
                opacity: 1,
            },
        },
    };

    return (
        <div className="absolute -bottom-1 -start-20 2xl:-start-28 -end-2 2xl:-end-4">
            <ClickAwayListener
                onClickAway={(e) => {
                    if (
                        triggerRef.current &&
                        !triggerRef.current.contains(e.target as Node) &&
                        store.tagsBlockExpanded
                    ) {
                        store.setTagsBlockExpanded(false);
                    }
                }}
            >
                <motion.div
                    className="absolute top-0 start-0 bg-white/60 dark:bg-[#3F3F3F]/60 z-30 w-full rounded-bl-[20px] max-h-[400px] overflow-y-auto overflow-x-clip"
                    style={{ backdropFilter: "blur(23px)" }}
                    variants={variants.tagsBlock}
                    initial={false}
                    animate={store.tagsBlockExpanded ? "expanded" : "simple"}
                    transition={{ ease: "easeOut" }}
                >
                    {store.loadingTags ? (
                        <div className="flex justify-center items-center">
                            <span>Загрузка...</span>
                        </div>
                    ) : (
                        <div className="flex gap-3 flex-wrap p-[12px]">
                            {store.tags.map((el) => {
                                if (domainStore.theme !== "dark") {
                                    return (
                                        <motion.div
                                            key={el.id}
                                            className="rounded-xl tag-style font-semibold hover:cursor-pointer"
                                            style={{ backgroundColor: `#${el.color}` }}
                                            variants={variants.tagsItem}
                                            initial={variants.tagsItem.simple}
                                            animate={store.tagsFilter.includes(el.id) ? "selected" : "simple"}
                                            onClick={() => {
                                                store.swapTagsFilter(el.id);
                                                store.fetchNews();
                                                store.setSlide(0);
                                            }}
                                        >
                                            #{el.name.toUpperCase()}
                                        </motion.div>
                                    );
                                }
                                return (
                                    <div
                                        key={el.id}
                                        className={`rounded-xl tag-style font-semibold hover:cursor-pointer transition-all ${
                                            store.tagsFilter.includes(el.id)
                                                ? "dark-primary-400 opacity-100"
                                                : "bg-slate-500 opacity-70"
                                        }`}
                                        onClick={() => {
                                            store.swapTagsFilter(el.id);
                                            store.fetchNews();
                                            store.setSlide(0);
                                        }}
                                    >
                                        #{el.name.toUpperCase()}
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </motion.div>
            </ClickAwayListener>
        </div>
    );
};

export default observer(TagsBlock);
