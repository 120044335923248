import { makeAutoObservable } from "mobx";
import { enqueueSnackbar } from "notistack";
import dayjs from "dayjs";
import api from "../../../../http";

export default class VacationStore {
    baseUrlPath: string = "/admin/vacation/";
    dateFormat: string = "YYYY-MM-DD";
    startMonth: string = "";
    startDay: string = "";
    startDays: Array<number> = [];

    endMonth: string = "";
    endDay: string = "";
    endDays: Array<number> = [];

    loadingIntervalGet: boolean = false;
    loadingIntervalUpdate: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    setStartMonth = (val: string) => {
        this.startMonth = val;
    };

    setStartDay = (val: string) => {
        this.startDay = val;
    };

    setStartDays = (days: Array<number>) => {
        this.startDays = days;
    };

    setEndMonth = (val: string) => {
        this.endMonth = val;
    };

    setEndDay = (val: string) => {
        this.endDay = val;
    };

    setEndDays = (days: Array<number>) => {
        this.endDays = days;
    };

    setLoadingIntervalUpdate = (bool: boolean) => {
        this.loadingIntervalUpdate = bool;
    };

    setLoadingIntervalGet = (bool: boolean) => {
        this.loadingIntervalGet = bool;
    };

    setDays = (month: string, daysSetter: Function) => {
        const numberOfDays = dayjs({ month: month }).endOf("month").date();
        let daysList: Array<number> = [];
        // Отдельно опишем февраль, так как может быть високосный год
        if (Number(month) !== 1) {
            for (let i = 1; i <= numberOfDays; i++) {
                daysList.push(i);
            }
        } else {
            for (let i = 1; i <= 28; i++) {
                daysList.push(i);
            }
        }

        daysSetter(daysList);
    };

    getVacationInterval = async () => {
        this.setLoadingIntervalGet(true);
        await api
            .get(this.baseUrlPath + "interval/")
            .then((resp) => {
                if (resp.data && resp.data.time_start && resp.data.time_end) {
                    const splitTimeStart = resp.data.time_start.split(" ");
                    const splitTimeEnd = resp.data.time_end.split(" ");
                    this.setStartMonth(String(Number(splitTimeStart[1]) - 1));
                    this.setStartDay(splitTimeStart[0]);
                    this.setEndMonth(String(Number(splitTimeEnd[1]) - 1));
                    this.setEndDay(splitTimeEnd[0]);
                }
            })
            .catch((e) => {
                console.error(e);
                enqueueSnackbar("Ошибка запроса интервала планирования");
            })
            .finally(() => this.setLoadingIntervalGet(false));
    };

    saveVacationInterval = async () => {
        // Проверим, что дата окончания позже даты начала
        const dateStart = dayjs({ month: this.startMonth, date: this.startDay });
        const dateEnd = dayjs({ month: this.endMonth, date: this.endDay });

        if (dateStart.isAfter(dateEnd)) {
            enqueueSnackbar("Дата закрытия формы должна быть позже даты открытия");
            return;
        }

        this.setLoadingIntervalUpdate(true);
        await api
            .post(this.baseUrlPath + "interval/", {
                date_start: dateStart.format(this.dateFormat),
                date_end: dateEnd.format(this.dateFormat),
            })
            .then(() => enqueueSnackbar("Интервал изменён"))
            .catch((e) => {
                console.error(e);
                enqueueSnackbar("Ошибка обновления");
            })
            .finally(() => this.setLoadingIntervalUpdate(false));
    };
}
