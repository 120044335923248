import { Outlet } from "react-router-dom";
import { observer } from "mobx-react-lite";
import NotFound from "../pages/errors/404";
import { ReactNode, useContext } from "react";
import AuthContext, { AuthContextType } from "../context/AuthContext";

interface PermissionRouteInterface {
    permissionList: Array<"portalAdmin">;
}

const PermissionRoute = ({ permissionList }: PermissionRouteInterface) => {
    const { domainStore } = useContext(AuthContext) as AuthContextType;

    for (const permission of permissionList) {
        if (permission === "portalAdmin" && !domainStore.isPortalAdmin) {
            return <NotFound />;
        }
    }
    return <Outlet />;
};

export default observer(PermissionRoute);
