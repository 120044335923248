import { useState, useRef, useEffect, BaseSyntheticEvent } from "react";
import { motion } from "framer-motion";
import { NewsItemInterface } from "./interfaces";
import dayjs from "dayjs";
import HomeNewsStore from "./store";
import Feedback from "../News/components/Feedback";
import { useNavigate } from "react-router-dom";
import useImageLoad from "../../hooks/useImageLoad";
import NavLink from "../../components/general/NavLink";
import DOMPurify from "dompurify";

interface NewsCardInterface {
    slide: number;
    newsItem: NewsItemInterface;
    idx: number;
    store: HomeNewsStore;
}

const NewsCard = ({ slide, newsItem, idx, store }: NewsCardInterface) => {
    const [loaded] = useImageLoad(newsItem.title_image);
    const [textOverflow, setTextOverflow] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (ref.current) {
            setTextOverflow(ref.current.scrollHeight !== ref.current.clientHeight);
        }
    }, []);

    const newsViewLink = (id: number) => {
        navigate(`/news/view/${id}`);
    };

    return (
        <motion.div
            initial={{ opacity: slide === idx ? 1 : 0 }}
            animate={{ opacity: slide === idx ? 1 : 0 }}       
            className="w-full shrink-0 general-card overflow-hidden"
        >
            <a
                href={`/news/view/${newsItem.id}`}
                onClick={(e: BaseSyntheticEvent) => {
                    e.preventDefault();
                    newsViewLink(newsItem.id);
                }}
                className={`h-1/2 overflow-hidden flex justify-center border-b-[1px] dark:border-[#717171] relative ${
                    !loaded && "animate-pulse bg-slate-200 cursor-wait"
                }`}
            >
                {loaded && (
                    <img src={newsItem.title_image} className="absolute object-cover w-full h-full blur-lg -z-10" />
                )}
                {loaded && <img src={newsItem.title_image} className="object-contain" />}
            </a>
            <div className="bg-white dark:dark-surface-300 flex flex-col justify-between h-1/2 p-3 sm:p-4 2xl:px-8 2xl:py-6">
                <div>
                    <div className="font-bold text-lg sm:text-xl 2xl:text-2xl text-nowrap overflow-hidden text-ellipsis w-full">
                        <a
                            href={`/news/view/${newsItem.id}`}
                            onClick={(e: BaseSyntheticEvent) => {
                                e.preventDefault();
                                newsViewLink(newsItem.id);
                            }}
                        >
                            {newsItem.title}
                        </a>
                    </div>
                    <div className="mt-1 2xl:mt-2 text-[#8F9DB9] text-xs sm:text-sm flex justify-between avx-p flex-wrap">
                        <div>{dayjs(newsItem.published_at).format("LLL")}</div>
                        <NavLink href={`/users/profile/${newsItem.author.id}`}>
                            {newsItem.author.last_name} {newsItem.author.first_name}
                        </NavLink>
                    </div>
                    <div ref={ref} className="max-h-20 sm:max-h-36 lg:max-h-56 2xl:max-h-52 overflow-y-hidden mt-1 2xl:mt-2 relative">
                        <div
                            className="text-sm whitespace-pre-wrap break-word font-medium"
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(newsItem.text) }}
                        />
                        {textOverflow && (
                            <div className="absolute bottom-0 left-0 h-12 sm:h-16 bg-gradient-to-b from-white/0 to-white/100 dark:from-[#3F3F3F]/0 dark:to-[#3F3F3F]/100 w-full"></div>
                        )}
                    </div>
                    <a
                        className="mt-2 inline-block text-[#2B5BB9] dark:dark-primary-text-400 text-sm"
                        href={`/news/view/${newsItem.id}`}
                        onClick={(e: BaseSyntheticEvent) => {
                            e.preventDefault();
                            newsViewLink(newsItem.id);
                        }}
                    >
                        Читать полностью
                    </a>
                </div>
                <Feedback news={newsItem} store={store} />
            </div>
        </motion.div>
    );
};

export default NewsCard;
