import { useState, useEffect } from "react";
import dayjs from "dayjs";
import NightsStayIcon from "@mui/icons-material/NightsStay";
import WbTwilightIcon from "@mui/icons-material/WbTwilight";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import BedtimeIcon from "@mui/icons-material/Bedtime";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import "./styles.css";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { Drawer, IconButton } from "@mui/material";
import MyDrawer from "../MyDrawer";
import HeaderStore from "./store";
import CorpSearch from "./components/CorpSearch";
import ProfileMenu from "./components/ProfileMenu";
import ThemeSwitcher from "../../../components/ThemeSwitcher";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

const Header = () => {
    const [headerStore] = useState(() => new HeaderStore());

    const chooseIcon = () => {
        const currTime = dayjs();

        const compareTime = dayjs(`1970-00-00 ${currTime.format("LT")}`);
        if (compareTime.isBefore(dayjs("1970-00-00 05:00"), "minutes")) {
            return <BedtimeIcon sx={{ fontSize: 30 }} />;
        } else if (compareTime.isBefore(dayjs("1970-00-00 12:00"), "minutes")) {
            return <WbTwilightIcon sx={{ fontSize: 30 }} />;
        } else if (compareTime.isBefore(dayjs("1970-00-00 18:00"), "minutes")) {
            return <WbSunnyIcon sx={{ fontSize: 30 }} />;
        } else if (compareTime.isBefore(dayjs("1970-00-00 23:00"), "minutes")) {
            return <NightsStayIcon sx={{ fontSize: 30 }} />;
        } else {
            return <BedtimeIcon sx={{ fontSize: 30 }} />;
        }
    };

    const handleModalOpen = () => {
        headerStore.setModalOpen(true);
    };

    const handleModalClose = () => {
        headerStore.setModalOpen(false);
    };

    const [icon, setIcon] = useState(chooseIcon());
    const navigate = useNavigate();

    useEffect(() => {
        setInterval(() => {
            const currTime = dayjs();
            headerStore.setHours(currTime.format("HH"));
            headerStore.setMinutes(currTime.format("mm"));
            setIcon(chooseIcon());
        }, 1000);
    }, []);

    return (
        <div className="xl:pt-[32px]">
            <div className="h-[50px] w-screen bg-white dark:dark-surface-300 px-2 flex items-center justify-between xl:w-full xl:h-[64px] xl:rounded-[45px] xl:px-[35px]">
                {/* Для десктопа */}
                <div className="xl:flex items-center avilex-color dark:dark-primary-text-400 hidden">
                    <div className="text-[#ABABAB]">{icon}</div>
                    <span className="ml-[18px] flex items-center">
                        <div className="font-medium time-font">{headerStore.hours}</div>
                        <div className="font-medium time-font">{headerStore.minutes}</div>
                    </span>
                </div>
                <div className="hidden items-center justify-between xl:flex">
                    <div className="mr-[157px]">
                        <CorpSearch store={headerStore} />
                    </div>
                    <div className="mr-[20px] flex items-center gap-5">
                        <ThemeSwitcher />
                        <IconButton title="Календарь" onClick={() => navigate("/calendar")}>
                            <CalendarMonthIcon className="!text-3xl dark:!text-[#ABABAB]"/>
                        </IconButton>
                    </div>
                    <div>
                        <ProfileMenu />
                    </div>
                </div>

                {/* Для телефона */}
                <div className="xl:hidden">
                    <IconButton onClick={() => navigate("/")}>
                        <HomeRoundedIcon />
                    </IconButton>
                </div>
                <div className="xl:hidden flex items-center">
                    <IconButton onClick={() => navigate("/calendar")}>
                        <CalendarMonthIcon />
                    </IconButton>
                    <div>
                        <IconButton onClick={handleModalOpen}>
                            <MenuRoundedIcon />
                        </IconButton>
                        <Drawer
                            anchor="top"
                            open={headerStore.modalOpen}
                            onClose={handleModalClose}
                            className="xl:hidden"
                        >
                            <MyDrawer setOpen={headerStore.setModalOpen} />
                        </Drawer>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default observer(Header);
