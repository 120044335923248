import { observer } from "mobx-react-lite";
import PathWrapper from "../PathWrapper";
import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { monthList } from "../../../../components/MyCalendar/common/constants";
import { BaseSyntheticEvent, useEffect, useState } from "react";
import VacationStore from "./store";
import "./styles.css";

const Vacation = () => {
    const maxHeight = 400;
    const [store] = useState(() => new VacationStore());

    useEffect(() => {
        const fetchInterval = async () => {
            await store.getVacationInterval();
        };
        fetchInterval();
    }, []);

    useEffect(() => {
        store.setDays(store.startMonth, store.setStartDays);
    }, [store.startMonth]);

    useEffect(() => {
        store.setDays(store.endMonth, store.setEndDays);
    }, [store.endMonth]);

    const handleStartMonthChange = (e: SelectChangeEvent) => {
        store.setStartMonth(e.target.value);
        store.setStartDay("");
    };

    const handleStartDateChange = (e: SelectChangeEvent) => {
        store.setStartDay(e.target.value);
    };

    const handleEndMonthChange = (e: SelectChangeEvent) => {
        store.setEndMonth(e.target.value);
        store.setEndDay("");
    };

    const handleEndDateChange = (e: SelectChangeEvent) => {
        store.setEndDay(e.target.value);
    };

    const submitSaveInterval = async (e: BaseSyntheticEvent) => {
        e.preventDefault();
        await store.saveVacationInterval();
    };

    return (
        <PathWrapper>
            <form onSubmit={submitSaveInterval}>
                <h1 className="font-semibold text-lg mb-3">Интервал планирования отпуска на следующий год</h1>
                <div className="grid gap-3 grid-cols-1 mb-5 interval-input-block">
                    <div>
                        <h1 className="mb-3"> Время открытия формы</h1>
                        <div className="flex gap-3">
                            {!store.loadingIntervalGet ? (
                                <FormControl fullWidth size="small">
                                    <InputLabel>Месяц</InputLabel>
                                    <Select
                                        label={"Месяц"}
                                        value={store.startMonth}
                                        onChange={handleStartMonthChange}
                                        MenuProps={{ PaperProps: { sx: { maxHeight: maxHeight } } }}
                                        required
                                    >
                                        {monthList.map((el, idx) => (
                                            <MenuItem key={idx} value={idx}>
                                                {el}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            ) : (
                                <div className="loading-color w-full rounded" />
                            )}
                            {!store.loadingIntervalGet ? (
                                <FormControl fullWidth size="small">
                                    <InputLabel>День</InputLabel>
                                    <Select
                                        label={"День"}
                                        value={store.startDay}
                                        onChange={handleStartDateChange}
                                        MenuProps={{ PaperProps: { sx: { maxHeight: maxHeight } } }}
                                        disabled={store.startMonth === ""}
                                        required
                                    >
                                        {store.startDays.map((el, idx) => (
                                            <MenuItem key={idx} value={el}>
                                                {el}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            ) : (
                                <div className="loading-color w-full rounded" />
                            )}
                        </div>
                    </div>
                    <div>
                        <h1 className="mb-3"> Время закрытия формы</h1>
                        <div className="flex gap-3">
                            {!store.loadingIntervalGet ? (
                                <FormControl fullWidth size="small">
                                    <InputLabel>Месяц</InputLabel>
                                    <Select
                                        label={"Месяц"}
                                        value={store.endMonth}
                                        onChange={handleEndMonthChange}
                                        MenuProps={{ PaperProps: { sx: { maxHeight: maxHeight } } }}
                                        required
                                    >
                                        {monthList.map((el, idx) => (
                                            <MenuItem key={idx} value={idx}>
                                                {el}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            ) : (
                                <div className="loading-color w-full rounded" />
                            )}
                            {!store.loadingIntervalGet ? (
                                <FormControl fullWidth size="small">
                                    <InputLabel>День</InputLabel>
                                    <Select
                                        label={"День"}
                                        value={store.endDay}
                                        onChange={handleEndDateChange}
                                        MenuProps={{ PaperProps: { sx: { maxHeight: maxHeight } } }}
                                        disabled={store.endMonth === ""}
                                        required
                                    >
                                        {store.endDays.map((el, idx) => (
                                            <MenuItem key={idx} value={el}>
                                                {el}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            ) : (
                                <div className="loading-color w-full rounded" />
                            )}
                        </div>
                    </div>
                </div>
                {!store.loadingIntervalGet ? (
                    <button
                        className={`avilex-surface-color p-2 rounded font-semibold ${
                            store.loadingIntervalUpdate ? "opacity-70" : ""
                        }`}
                        type="submit"
                        disabled={store.loadingIntervalUpdate}
                    >
                        Сохранить
                    </button>
                ) : (
                    <div className="loading-color w-28 rounded h-10" />
                )}
            </form>
        </PathWrapper>
    );
};

export default observer(Vacation);
