import { observer } from "mobx-react-lite";
import BirthdayStore from ".././BirthdayBlock/store";
import RecentEmployeesStore from "../RecentEmployeesBlock/store";
import ProfileImageSkeleton from "../../../../../../components/skeletons/ProfileImageSkeleton";
import "./styles.css";
import { BaseSyntheticEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";

interface UsersBlockInterface {
    store: BirthdayStore | RecentEmployeesStore;
    loadingElementsNum: number;
}

const UsersBlock = observer(({ store, loadingElementsNum }: UsersBlockInterface) => {
    const navigate = useNavigate();
    const [loadingElArray] = useState<Array<number>>(() => {
        let arr: Array<number> = [];
        for (let i = 0; i < loadingElementsNum; i++) {
            arr.push(i);
        }
        return arr;
    });

    const handleUserRedirect = (e: BaseSyntheticEvent, userId: number) => {
        e.preventDefault();
        navigate("/users/profile/" + userId);
    };

    const handleBirthday = (day: Dayjs) => {
        if (day.isSame(dayjs(), "day")) {
            return <div className="avilex-color">Сегодня</div>;
        }
        if (day.isSame(dayjs().add(1, "day"), "day")) {
            return "Завтра";
        }
        return day.format("D MMMM");
    };

    if (store.loading) {
        return (
            <div className="general-users-block loading">
                {loadingElArray.map((el) => (
                    <div key={el}>
                        <div className="loading-color" />
                        <div className="loading-color" />
                    </div>
                ))}
            </div>
        );
    } else {
        if (!!store.users.length) {
            return (
                <div className="general-users-block">
                    {store.users.map((el) => (
                        <a
                            key={el.id}
                            className="cursor-pointer"
                            onClick={(e) => handleUserRedirect(e, el.id)}
                            href={"/users/profile/" + el.id}
                        >
                            <ProfileImageSkeleton image={el.profile.thumbnail} className="general-user-image" />
                            <div>
                                <div className="text-sm font-semibold">
                                    {el.last_name} {el.first_name}
                                </div>
                                <div className="text-xs text-secondary">{el.profile.position?.name}</div>
                                {store.name === "recentEmployees" ? (
                                    <div className="text-xs mt-1 avilex-color">
                                        {el.profile.department?.company.name}
                                    </div>
                                ) : (
                                    <div className="text-xs">
                                        {handleBirthday(
                                            dayjs({
                                                date: el.profile.birthdate.d,
                                                month: el.profile.birthdate.m - 1,
                                                year: dayjs().year(),
                                            })
                                        )}
                                    </div>
                                )}
                            </div>
                        </a>
                    ))}
                </div>
            );
        } else {
            return <div className="no-results-block">Пользователи не найдены</div>;
        }
    }
});

export default UsersBlock;
