import { makeAutoObservable } from "mobx";
import api from "../../../../../../http";
import { enqueueSnackbar } from "notistack";
import dayjs from "dayjs";
import { SafeUserInterface } from "../../../../../../interfaces/users";

class Store {
    name: string = "birthday";
    loading: boolean = true;
    users: Array<SafeUserInterface> = [];

    constructor() {
        makeAutoObservable(this);
    }

    setLoading = (bool: boolean) => {
        this.loading = bool;
    };

    setUsers = (users: Array<SafeUserInterface>) => {
        this.users = users;
    };

    fetchUsers = async () => {
        this.setLoading(true);
        const daysNum = 7;
        await api
            .get("/users/", {
                params: {
                    page_size: 15,
                    ordering: "last_name,first_name,id",
                    birthdays_for_days: daysNum,
                },
            })
            .then((resp) => {
                // Необходимо вручную отсортировать дни, потому что может быть стык одного года с другим
                let tempList: Array<SafeUserInterface> = [];
                for (let i = 0; i < daysNum; i++) {
                    const day = dayjs().add(i, "days");
                    const filtered = resp.data.results.filter(
                        (el: SafeUserInterface) =>
                            el.profile.birthdate.m === day.month() + 1 && el.profile.birthdate.d === day.date()
                    );
                    tempList.push(...filtered);
                }
                this.setUsers(tempList);
            })
            .catch((e) => {
                console.error(e);
                enqueueSnackbar("Ошибка запроса дней рождения");
            })
            .finally(() => this.setLoading(false));
    };
}

export default Store;
