import { createTheme } from "@mui/material/styles";
import { grey } from "@mui/material/colors";

declare module "@mui/material/styles" {
    interface Palette {
        signature: Palette["primary"];
        lightBlue: Palette["primary"];
    }

    interface PaletteOptions {
        signature?: PaletteOptions["primary"];
        lightBlue?: PaletteOptions["primary"];
    }

    interface BreakpointOverrides {
        xs: true;
        sm: true;
        md: true;
        lg: true;
        xl: true;
        "2xl": true;
        mobile: false; // adds the `mobile` breakpoint
        tablet: false;
        laptop: false;
        desktop: false;
    }
}

declare module "@mui/material/Button" {
    interface ButtonPropsColorOverrides {
        signature: true;
        lightBlue: true;
    }
}

let theme = createTheme({
    colorSchemes: {
        light: {
            palette: {
                signature: {
                    main: "#ab2121",
                    light: "#ab2121",
                    dark: "#ab2121",
                    contrastText: "#ffffff",
                },
                secondary: {
                    main: grey[500],
                    contrastText: "#ffffff",
                },
            },
        },
        dark: {
            palette: {
                signature: {
                    main: "#7a5af5",
                    dark: "#7a5af5",
                    light: "#7a5af5",
                    contrastText: "#ffffff",
                },
                secondary: {
                    main: grey[500],
                    contrastText: "#ffffff",
                },
            },
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 640,
            md: 768,
            lg: 1024,
            xl: 1280,
            "2xl": 1536,
        },
    },
});
theme = createTheme(theme, {
    palette: {
        lightBlue: theme.palette.augmentColor({
            color: {
                main: "#E3EBF8",
            },
            name: "lightBlue",
        }),
    },
});

export default theme;
