import { useContext, useState, useEffect, BaseSyntheticEvent } from "react";
import { MyCalendarContext, MyCalendarContextType } from "../../context";
import ProfileImageSkeleton from "../../../skeletons/ProfileImageSkeleton";
import MonthStore from "../Month/store";
import { Dayjs } from "dayjs";
import "./styles.css";
import MonthSelector from "./components/MonthSelector";
import { observer } from "mobx-react-lite";
import MonthHeaderRow from "./components/MonthHeaderRow";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import { motion } from "framer-motion";
import { Tabs, Tab, TextField } from "@mui/material";
import SubordinatesList from "../SubordinatesList";
import CellDay from "./components/CellDay";
import { NoResults, Loading } from "../../../ListFeedback";
import { useInView } from "react-intersection-observer";

const ManagerView = () => {
    const { myCalendarStore } = useContext(MyCalendarContext) as MyCalendarContextType;
    const [allDays, setAllDays] = useState<Array<Dayjs>>([]);
    const [separatedDays, setSeparatedDays] = useState<Array<Array<Dayjs>>>([]);
    const [usersExpanded, setUsersExpanded] = useState<boolean>(true);
    const [tab, setTab] = useState<number>(0);
    const { ref } = useInView({
        threshold: 0.7,
        onChange: (inView, entry) => {
            if (inView && myCalendarStore.subordinatesHasMore && !myCalendarStore.loadingSubordinatesMore) {
                myCalendarStore.setSubordinatesPage(myCalendarStore.subordinatesPage + 1);
            }
        },
    });

    useEffect(() => {
        let tempAllDays: Array<Dayjs> = [];
        let tempSeparatedDays: Array<Array<Dayjs>> = [];

        for (const month of myCalendarStore.monthList) {
            const monthStore = new MonthStore(myCalendarStore.date.year(), month, myCalendarStore.events);
            let tempMonth = [];

            for (const week of monthStore.monthData) {
                for (const day of week) {
                    if (day) {
                        tempAllDays.push(day.date);
                        tempMonth.push(day.date);
                    }
                }
            }
            tempSeparatedDays.push(tempMonth);
        }
        setAllDays(tempAllDays);
        setSeparatedDays(tempSeparatedDays);
    }, [myCalendarStore.date]);

    const handleExpandedUsersChange = () => {
        setUsersExpanded((pv) => !pv);
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        if (newValue !== 0) {
            myCalendarStore.setCalendarRendered(false);
        }
        setTab(newValue);
    };

    const handleSearchChange = (e: BaseSyntheticEvent) => {
        myCalendarStore.setCalendarRendered(false);
        myCalendarStore.setSubordinateSearch(e.target.value);
    };

    return (
        <div>
            <div className="2xl:hidden mb-3">
                <Tabs value={tab} onChange={handleTabChange}>
                    <Tab label="График" />
                    <Tab label="Список" />
                </Tabs>
            </div>
            {tab === 0 ? (
                <div className="2xl:w-[60vw] base-layer p-1 md:p-3 lg:p-5">
                    <div className="mb-3 2xl:mb-5 flex justify-between items-center">
                        <div className="main-block-heading">График отпусков</div>
                        <div>
                            <TextField
                                fullWidth
                                label="Поиск сотрудника"
                                variant="outlined"
                                sx={{
                                    borderRadius: 2,
                                    "& .MuiInputBase-root": {
                                        borderRadius: 2,
                                    },
                                }}
                                onChange={handleSearchChange}
                                value={myCalendarStore.subordinateSearch}
                                size="small"
                                className="bg-white dark:dark-surface-300"
                                // disabled={myCalendarStore.loadingSubordinates}
                            />
                        </div>
                    </div>

                    {!myCalendarStore.loadingSubordinates ? (
                        <div>
                            <div className="w-full">
                                <div className="w-full flex gap-1 md:gap-2 xl:gap-3">
                                    <div className="shrink-0">
                                        <div className="relative">
                                            <div className="week-day" />
                                            <div className="week-day" />
                                            <div className="row-padding" />
                                            <div className="month-selector-block flex justify-center items-center">
                                                {!!myCalendarStore.subordinates.length && (
                                                    <button
                                                        className="bg-white dark:dark-surface-400 rounded flex justify-center items-center py-1 px-2 2xl:hidden"
                                                        onClick={handleExpandedUsersChange}
                                                    >
                                                        {usersExpanded ? (
                                                            <FirstPageIcon className="!text-base md:!text-xl" />
                                                        ) : (
                                                            <LastPageIcon className="!text-base md:!text-xl" />
                                                        )}
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                        <table>
                                            <tbody>
                                                {myCalendarStore.subordinates.map((el, idx) => (
                                                    <tr
                                                        key={el.id}
                                                        ref={
                                                            idx === myCalendarStore.subordinates.length - 2 ? ref : null
                                                        }
                                                    >
                                                        <td className="dark:dark-surface-500 sticky-column fixed-block border border-transparent">
                                                            <div className="cell flex items-center">
                                                                <div className="flex items-center  bg-white dark:dark-surface-400 w-full p-1 md:p-2 rounded-3xl">
                                                                    <ProfileImageSkeleton
                                                                        image={el.profile.thumbnail}
                                                                        className="w-[28px] text-[28px] md:w-[35px] md:text-[35px]"
                                                                    />
                                                                    <motion.span
                                                                        animate={{
                                                                            width: usersExpanded ? "unset" : 0,
                                                                            opacity: usersExpanded ? 1 : 0,
                                                                        }}
                                                                        initial={false}
                                                                        className="font-semibold text-xs md:text-sm lg:text-base text-nowrap max-w-20 md:max-w-48 overflow-hidden text-ellipsis"
                                                                    >
                                                                        <span className="ms-1 md:ms-3">
                                                                            {el.last_name}
                                                                        </span>{" "}
                                                                        {el.first_name &&
                                                                            el.first_name
                                                                                .split(" ")
                                                                                .map((el) => `${el.slice(0, 1)}.`)}
                                                                    </motion.span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="grow min-w-0 bg-white dark:dark-surface-400 px-1 sm:px-3 rounded-xl overflow-hidden">
                                        <div className="flex justify-center items-center month-selector-block">
                                            <MonthSelector />
                                        </div>
                                        {!!myCalendarStore.subordinates.length ? (
                                            <div>
                                                <div className="overflow-auto pb-2">
                                                    <table className="table-fixed">
                                                        <thead>
                                                            <tr>
                                                                {separatedDays.map((el, idx) => (
                                                                    <MonthHeaderRow
                                                                        key={idx}
                                                                        monthNum={idx}
                                                                        monthData={el}
                                                                    />
                                                                ))}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                {allDays.map((day, idx) => (
                                                                    <td key={idx} className="">
                                                                        <div className="h-0 opacity-0">hello</div>
                                                                    </td>
                                                                ))}
                                                            </tr>
                                                            {myCalendarStore.subordinates.map((subordinate) => (
                                                                <tr key={subordinate.id}>
                                                                    {allDays.map((day, idx) => (
                                                                        <CellDay
                                                                            key={idx}
                                                                            day={day}
                                                                            subordinate={subordinate}
                                                                        />
                                                                    ))}
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="lower-padding" />
                                            </div>
                                        ) : (
                                            <div className="py-2">
                                                <NoResults />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {myCalendarStore.loadingSubordinatesMore && (
                                    <div className="flex justify-center mt-2">
                                        <Loading />
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className="grid grid-cols-8 gap-3">
                            <div className="col-span-2 rounded-xl loading-color h-36"></div>
                            <div className="col-span-6 rounded-xl loading-color h-36"></div>
                        </div>
                    )}
                </div>
            ) : (
                <SubordinatesList />
            )}
        </div>
    );
};

export default observer(ManagerView);
