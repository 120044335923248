import { makeAutoObservable, reaction } from "mobx";
import dayjs, { Dayjs } from "dayjs";
import api from "../../http";
import { enqueueSnackbar } from "notistack";
import Cookies from "universal-cookie";
import DomainStore from "../../stores/domainStore";

export type ViewType = "year" | "month";
export type FetchType = "birthdate" | "vacation" | "holidays";
export type VacationModeType = "planMode" | "createMode";
export type VacationItemType = "createVacation" | "planVacation";
export type VacationExtraType = "Рабочий" | "Предпраздничный" | "Праздник" | "Суббота" | "Воскресенье";
export type VacationStatusType = "draft" | "moderate_employees_manager";
export const vacationTypeModeMap = {
    planMode: "planVacation",
    createMode: "createVacation",
};

interface FilterInterface {
    (el: any): boolean;
}

interface SortInterface {
    (a: any, b: any): number;
}

interface ProfileInterface {
    thumbnail: string;
}

export interface SafeUserInterface {
    id: number;
    last_name: string;
    first_name: string;
    profile: ProfileInterface;
}

interface SubordinateVacationTypeInterface {
    id: number;
    name: "planned";
    description: string;
}

interface SubordinateVacationStatusInterface {
    id: number;
    name: "moderate_employees_manager";
    description: string;
}

interface ApproveInterface {
    id: number;
    approved: boolean | null;
    comment: string | null;
    status: SubordinateVacationStatusInterface;
    approve_user: SafeUserInterface;
}

export interface SubordinateVacationInterface {
    id: number;
    date_start: string;
    date_end: string;
    type: SubordinateVacationTypeInterface;
    status: SubordinateVacationStatusInterface;
    approve_set: Array<ApproveInterface>;
    intersection_list: Array<string>;
}

export interface SubordinateInterface extends SafeUserInterface {
    vacations: Array<SubordinateVacationInterface>;
}

interface DateTimeInterface {
    d: number;
    M: number;
    y?: number;
}

export interface EventInterface {
    type: FetchType;
    extra_type: VacationExtraType;
    description: string;
    datetime: DateTimeInterface;
    hidden?: boolean;
}

export interface VacationItemInterface {
    id?: number;
    dateStart: Dayjs;
    dateEnd: Dayjs;
    type: VacationItemType;
    hidden: boolean;
    usedDays: number;
    status: VacationStatusType;
    approve_set?: Array<ApproveInterface>;
}

export default class MyCalendarStore {
    domainStore: DomainStore;
    cookies: Cookies;
    view: ViewType;
    viewList: Array<ViewType> = [];
    date: Dayjs;
    monthList: Array<number> = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    showToday: boolean;
    fetchList: Array<FetchType>;
    events: Array<EventInterface> = [];
    showTodayEvents: boolean;
    vacationPlanAndCreate: boolean;
    remainingVacationDays: number | null = null;
    constRemainingPlanDays: number = 28;
    remainingPlanDays: number = this.constRemainingPlanDays;
    remainingPlanDaysInDec: number | null = null;
    vacationMode: VacationModeType = "createMode";
    selectedDayStart: Dayjs | null = null;
    selectedDayEnd: Dayjs | null = null;
    vacationList: Array<VacationItemInterface> = [];
    scrollDate: Dayjs | null = null; // Дата, к которой будет осуществлён принудительный скролл
    eventsFilter: boolean;
    dateFormat: string = "YYYY-MM-DD";
    canRemovePlanned: boolean = false;

    managerMode: boolean | null;
    calendarRendered: boolean = false;
    subordinates: Array<SubordinateInterface> = [];
    subordinatesPage: number = 1;
    subordinatesPageSize: number = 20;
    subordinatesHasMore: boolean = false;
    subordinateSearch: string = "";
    onlyIntersecting: boolean = false;
    onlyNeedApprove: boolean = false;
    subordinatesFilter: FilterInterface = (el: SubordinateInterface) => true;

    loadingVacations: boolean = true;
    loadingVacationsInfo: boolean = true;
    loadingSubordinates: boolean = true;
    loadingSubordinatesMore: boolean = false;

    filterNotApproved: FilterInterface = (el: ApproveInterface) => {
        return el.approved === false;
    };

    sortNotApproved: SortInterface = (a: VacationItemInterface, b: VacationItemInterface) => {
        const filteredA = a.approve_set?.filter(this.filterNotApproved).length;
        const filteredB = b.approve_set?.filter(this.filterNotApproved).length;
        if (filteredA && !filteredB) {
            return -1;
        } else if (!filteredA && filteredB) {
            return 1;
        }
        return 0;
    };

    filterNotApprovedVacations: FilterInterface = (el: VacationItemInterface) => {
        return !!el.approve_set?.filter(this.filterNotApproved).length;
    };

    constructor(
        domainStore: DomainStore,
        year: number,
        month: number,
        view: ViewType,
        viewList: Array<ViewType>,
        showToday: boolean,
        fetchList: Array<FetchType>,
        showTodayEvents: boolean,
        vacationPlanAndCreate: boolean,
        eventsFilter: boolean,
        isManager: boolean
    ) {
        this.domainStore = domainStore;
        this.viewList = viewList;
        this.date = dayjs({ year, month });
        this.showToday = showToday;
        this.view = view;
        this.fetchList = fetchList;
        this.showTodayEvents = showTodayEvents;
        this.vacationPlanAndCreate = vacationPlanAndCreate;
        this.eventsFilter = eventsFilter;
        this.cookies = new Cookies(null, { path: window.location.pathname });
        this.managerMode = isManager ? false : null;

        this.fetchRemainingVacationDays();
        makeAutoObservable(this);

        reaction(
            () => this.subordinatesPage,
            () => {
                this.fetchSubordinates();
            }
        );

        reaction(
            () => this.date,
            async (newVal, pv) => {
                if (this.managerMode && newVal.year() !== pv.year()) {
                    this.handleSubordinatesChange();
                }
            }
        );

        reaction(
            () => this.managerMode,
            async (newVal) => {
                if (this.managerMode) {
                    this.handleSubordinatesChange();
                }
            }
        );

        reaction(
            () => this.subordinateSearch,
            (val) => {
                this.handleSubordinatesChange();
            },
            {
                delay: 1000,
            }
        );
    }

    setView(view: ViewType) {
        this.view = view;
    }

    setDate(date: Dayjs) {
        this.date = date;
    }

    setEvents(events: Array<EventInterface>) {
        this.events = events;
    }

    setRemainingVacationDays(days: number) {
        this.remainingVacationDays = days;
    }

    setRemainingPlanDays(days: number) {
        this.remainingPlanDays = days;
    }

    setVacationMode(mode: VacationModeType) {
        this.vacationMode = mode;
    }

    setSelectedDayStart(date: Dayjs | null) {
        this.selectedDayStart = date;
    }

    setSelectedDayEnd(date: Dayjs | null) {
        this.selectedDayEnd = date;
    }

    setVacationList(list: Array<VacationItemInterface>) {
        this.vacationList = list;
    }

    setScrollDate(date: Dayjs | null) {
        this.scrollDate = date;
    }

    setLoadingVacations = (bool: boolean) => {
        this.loadingVacations = bool;
    };

    setManagerMode = (bool: boolean) => {
        this.managerMode = bool;
    };

    setSubordinates = (subordinates: Array<SubordinateInterface>) => {
        this.subordinates = subordinates;
    };

    setLoadingSubordinates = (bool: boolean) => {
        this.loadingSubordinates = bool;
    };

    setLoadingSubordinatesMore = (bool: boolean) => {
        this.loadingSubordinatesMore = bool;
    };

    setCanRemovePlanned = (bool: boolean) => {
        this.canRemovePlanned = bool;
    };

    setCalendarRendered = (bool: boolean) => {
        this.calendarRendered = bool;
    };

    setSubordinateSearch = (q: string) => {
        this.subordinateSearch = q;
    };

    setSubordinatesPage = (page: number) => {
        this.subordinatesPage = page;
    };

    setSubordinatesHasMore = (bool: boolean) => {
        this.subordinatesHasMore = bool;
    };

    setOnlyIntersecting = (bool: boolean) => {
        this.onlyIntersecting = bool;
    };

    setOnlyNeedApprove = (bool: boolean) => {
        this.onlyNeedApprove = bool;
    };

    setLoadingVacationsInfo = (bool: boolean) => {
        this.loadingVacationsInfo = bool;
    };

    setRemainingPlanDaysInDec = (num: number) => {
        this.remainingPlanDaysInDec = num;
    };

    async fetchEvents() {
        const params = new URLSearchParams();

        for (const item of this.fetchList) {
            params.append("type", item);
        }
        if (this.view === "month") {
            params.append(
                "date_start",
                dayjs({ year: this.date.year(), month: this.date.month(), day: 1 }).format(this.dateFormat)
            );
            params.append(
                "date_end",
                dayjs({ year: this.date.year(), month: this.date.month() }).endOf("month").format(this.dateFormat)
            );
        } else {
            params.append("date_start", dayjs({ year: this.date.year() }).startOf("year").format(this.dateFormat));
            params.append("date_end", dayjs({ year: this.date.year() }).endOf("year").format(this.dateFormat));
        }

        await api
            .get("/calendar/", { params: params })
            .then((res) => {
                if (this.eventsFilter) {
                    const tempEvents: Array<EventInterface> = [];
                    for (const event of res.data) {
                        if (event.type === "birthdate") {
                            this.cookies.get("birthdateFilterChecked") === undefined
                                ? (event.hidden = false)
                                : (event.hidden = !this.cookies.get("birthdateFilterChecked"));
                        } else if (event.extra_type === "Предпраздничный") {
                            this.cookies.get("preHolidayFilterChecked") === undefined
                                ? (event.hidden = false)
                                : (event.hidden = !this.cookies.get("preHolidayFilterChecked"));
                        }
                        tempEvents.push(event);
                    }
                    this.setEvents(tempEvents);
                } else {
                    this.setEvents(res.data);
                }
            })
            .catch((e) => {
                console.error(e);
                enqueueSnackbar("Ошибка запроса событий");
            });
    }

    async fetchHolidays() {
        this.setLoadingVacations(true);
        await api
            .get("/vacation/vacations/", {
                params: {
                    date_start__gte: this.date.startOf("year").format(this.dateFormat),
                    date_end__lte: this.date.endOf("year").format(this.dateFormat),
                },
            })
            .then((resp) => {
                let newList: Array<VacationItemInterface> = [];
                let remainingPlanDays = this.constRemainingPlanDays;
                for (const vacation of resp.data) {
                    let hidden: boolean;
                    if (this.vacationMode === "planMode") {
                        if (vacation.type.name === "planned") {
                            hidden = false;
                        } else {
                            hidden = true;
                        }
                    } else {
                        if (vacation.type.name === "createMode") {
                            hidden = false;
                        } else {
                            hidden = true;
                        }
                    }
                    newList.push({
                        id: vacation.id,
                        dateStart: dayjs(vacation.date_start),
                        dateEnd: dayjs(vacation.date_end),
                        type: vacation.type.name === "planned" ? "planVacation" : "createVacation",
                        hidden: hidden,
                        usedDays: vacation.used_days,
                        status: vacation.status.name,
                        approve_set: vacation.approve_set,
                    });
                    if (vacation.type.name === "planned") remainingPlanDays -= vacation.used_days;
                }
                newList.sort(this.sortNotApproved);
                if (newList.filter(this.filterNotApprovedVacations).length) this.setCanRemovePlanned(true);

                this.setVacationList(newList);
                this.setRemainingPlanDays(remainingPlanDays);
            })
            .catch((e) => {
                console.error(e);
                enqueueSnackbar("Ошибка запроса отпусков");
            })
            .finally(() => this.setLoadingVacations(false));
    }

    async fetchRemainingVacationDays() {
        this.setLoadingVacationsInfo(true);
        await api
            .get("/vacation/info/remaining-days/")
            .then((resp) => {
                this.setRemainingVacationDays(resp.data.vacation_days);
                this.setRemainingPlanDaysInDec(resp.data.vacation_days_in_dec);
            })
            .catch((e) => {
                console.error(e);
                enqueueSnackbar("Ошибка запроса оставшихся дней отпуска");
            })
            .finally(() => {
                this.setLoadingVacationsInfo(false);
            });
    }

    async fetchSubordinates() {
        if (this.subordinatesPage === 1) {
            this.setLoadingSubordinates(true);
        } else {
            this.setLoadingSubordinatesMore(true);
        }
        await api
            .get("/vacation/subordinates/", {
                params: {
                    page: this.subordinatesPage,
                    page_size: this.subordinatesPageSize,
                    search: this.subordinateSearch,
                    date_start: this.date.startOf("year").format(this.dateFormat),
                    date_end: this.date.endOf("year").format(this.dateFormat),
                    only_intersecting: this.onlyIntersecting,
                    only_need_approve: this.onlyNeedApprove,
                },
            })
            .then((resp) => {
                if (this.subordinatesPage === 1) {
                    this.setSubordinates(resp.data.results);
                } else {
                    this.setSubordinates([...this.subordinates, ...resp.data.results]);
                }
                this.setSubordinatesHasMore(!!resp.data.next);
            })
            .catch((e) => {
                enqueueSnackbar("Ошибка запроса отпусков подчинённых");
                console.error(e);
            })
            .finally(() => {
                this.setLoadingSubordinates(false);
                this.setLoadingSubordinatesMore(false);
            });
    }

    handleSubordinatesChange = () => {
        if (this.subordinatesPage === 1) {
            this.fetchSubordinates();
        } else {
            this.setSubordinatesPage(1);
        }
    };

    async refuseVacation(vacation_id: number, comment: string) {
        await api
            .post("/vacation/manager-actions/refuse/", [{ vacation_id, comment, action: "refuse" }])
            .then(() => {
                const tempList: Array<SubordinateInterface> = [...this.subordinates];
                tempList.map((user) => {
                    user.vacations.map((vacation) => {
                        if (vacation.id === vacation_id) {
                            vacation.approve_set.map((approve) => {
                                if (
                                    approve.approve_user.id === this.domainStore.user.id &&
                                    approve.status.name === "moderate_employees_manager"
                                ) {
                                    approve.approved = false;
                                    approve.comment = comment;
                                }
                                return approve;
                            });
                        }
                        return vacation;
                    });
                    return user;
                });
                this.setSubordinates(tempList);
            })
            .catch((e) => {
                enqueueSnackbar("Ошибка отказа в отпуске");
                console.error(e);
            });
    }
}
