import { Button } from "@mui/material";
import { useEffect, useContext, useState } from "react";
import { MyCalendarContext, MyCalendarContextType } from "../../../context";
import api from "../../../../../http";
import { enqueueSnackbar } from "notistack";

interface SendVacationInterface {
    date_start: string;
    date_end: string;
    vacation_id?: number;
}

const SubmitPlanButton = () => {
    const { myCalendarStore } = useContext(MyCalendarContext) as MyCalendarContextType;
    const [disabled, setDisabled] = useState<boolean>(true);

    useEffect(() => {
        // Не должно остаться неизрасходованных дней планируемого отпуска
        if (!(myCalendarStore.remainingPlanDays === 0)) {
            setDisabled(true);
            return;
        }
        // Должен быть период в 14 непрерывных дней
        let flag = true;
        for (const vacation of myCalendarStore.vacationList) {
            if (vacation.dateEnd.diff(vacation.dateStart, "days") + 1 >= 14) {
                flag = false;
                break
            }
        }
        if (flag) {
            setDisabled(true);
            return;
        }

        // Если нет ни одного периода в статусе черновика
        if (
            !!!myCalendarStore.vacationList.filter((el) => {
                if (el.type !== "planVacation") return false;
                if (el.status === "draft") return true;
                return false;
            }).length
        ) {
            setDisabled(true);
            return;
        }

        // Это временное решение
        if (myCalendarStore.vacationMode !== "planMode") {
            setDisabled(true);
            return;
        }

        // Если есть период с отказом
        if (myCalendarStore.vacationList.filter(myCalendarStore.filterNotApprovedVacations).length) {
            setDisabled(true);
            return;
        }

        setDisabled(false);
    }, [myCalendarStore.vacationList, myCalendarStore.remainingPlanDays]);

    const handleSubmit = async () => {
        const dateFormat = "YYYY-MM-DD";
        const data: Array<SendVacationInterface> = [];

        for (const item of myCalendarStore.vacationList) {
            const vacation: SendVacationInterface = {
                date_start: item.dateStart.format(dateFormat),
                date_end: item.dateEnd.format(dateFormat),
            };
            if (item.id) {
                vacation.vacation_id = item.id;
            }
            data.push(vacation);
        }

        await api
            .post("/vacation/create/planned/", data)
            .then(() => {
                myCalendarStore.fetchHolidays();
                myCalendarStore.setCanRemovePlanned(false);
                enqueueSnackbar("Отпуска отправлены на согласование");
            })
            .catch((e) => {
                enqueueSnackbar("Ошибка отправки отпусков");
                console.error(e);
            });
    };

    return (
        <div className="">
            <Button
                variant="contained"
                className="w-full"
                size="small"
                color="signature"
                disabled={disabled}
                onClick={handleSubmit}
            >
                Отправить
            </Button>
        </div>
    );
};

export default SubmitPlanButton;
