import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import { BaseSyntheticEvent } from "react";
import PersonIcon from "@mui/icons-material/Person";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { VacationItemInterface, SubordinateVacationInterface } from "../../store";

import { observer } from "mobx-react-lite";

interface VacationTrackInterface {
    open: boolean;
    onClose: (e: BaseSyntheticEvent) => void;
    vacation: VacationItemInterface | SubordinateVacationInterface;
}

interface StatusChipInterface {
    approved: boolean | null;
}

const StatusChip = observer(({ approved }: StatusChipInterface) => {
    let text: string = "";
    let color: string = "";
    if (approved === true) {
        color = "bg-green-500";
        text = "Согласован";
    } else if (approved === false) {
        color = "bg-red-600";
        text = "Отказ";
    } else {
        color = "bg-yellow-500";
        text = "На рассмотрении";
    }
    return <div className={`${color} text-sm rounded-md px-1 text-white`}>{text}</div>;
});

const VacationTrack = ({ open, onClose, vacation }: VacationTrackInterface) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                <span className="text-lg">Отслеживание статуса отпуска</span>
            </DialogTitle>
            <DialogContent>
                <div className="flex flex-col items-center gap-2">
                    <div>
                        <div className="text-yellow-500 flex items-center gap-2 text-sm mb-1">
                            <PersonIcon /> Согласование руководителем
                        </div>
                        <div className="flex flex-col gap-1">
                            {vacation.approve_set?.map((el, idx) => (
                                <div key={idx} className="text-sm">
                                    <div className="flex gap-2 items-center">
                                        <div>
                                            {el.approve_user.last_name} {el.approve_user.first_name}
                                        </div>
                                        <StatusChip approved={el.approved} />
                                    </div>
                                    {el.comment && <div className="italic">Комментарий: {el.comment}</div>}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="border-gray-300 h-12 border-dashed border opacity-80" />
                    <div className="text-gray-300 flex items-center gap-2 text-sm opacity-80">
                        <AccountBalanceIcon /> Согласование отделом кадров
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default observer(VacationTrack);
