import { makeAutoObservable } from "mobx";
import { SafeUserInterface } from "../../../../../../interfaces/users";
import api from "../../../../../../http";
import { enqueueSnackbar } from "notistack";
import dayjs from "dayjs";

class Store {
    name: string = "recentEmployees";
    loading: boolean = true;
    users: Array<SafeUserInterface> = [];

    constructor() {
        makeAutoObservable(this);
    }

    setLoading = (bool: boolean) => {
        this.loading = bool;
    };

    setUsers = (users: Array<SafeUserInterface>) => {
        this.users = users;
    };

    fetchUsers = async () => {
        this.setLoading(true);
        await api
            .get("/users/", {
                params: {
                    page_size: 15,
                    ordering: "-profile__company_joined_date",
                    recent_period: true,
                },
            })
            .then((resp) => {
                this.setUsers(resp.data.results);
            })
            .catch((e) => {
                console.error(e);
                enqueueSnackbar("Ошибка запроса новых сотрудников");
            })
            .finally(() => this.setLoading(false));
    };
}

export default Store;
