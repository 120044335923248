import { ReactNode, useState, useContext } from "react";
import { IconButton, Divider, List, ListItemText, Collapse, ListItemButton, ListItemIcon } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../context/AuthContext";
import { AuthContextType } from "../../../context/AuthContext";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import FactCheckIcon from '@mui/icons-material/FactCheck';

interface ChildInterface {
    text: string;
    path: string;
}

interface PagesInterface {
    text: string;
    icon: ReactNode;
    children: Array<ChildInterface>;
    path?: string;
}

interface ItemInterface {
    el: PagesInterface;
}

interface MyDrawerInterface {
    setOpen: Function;
}

const MyDrawer = ({ setOpen }: MyDrawerInterface) => {
    const { domainStore, uiStore } = useContext(AuthContext) as AuthContextType;
    const navigate = useNavigate();
    const pages = [
        {
            text: "Главная",
            icon: <HomeIcon />,
            children: [],
            path: "/",
        },
        {
            text: "Профиль",
            icon: <PersonIcon />,
            children: [],
            path: "/users/me",
        },
        {
            text: "Новости",
            icon: <NewspaperIcon />,
            children: [
                {
                    text: "Все новости",
                    path: "/news/all",
                },
                {
                    text: "Мои новости",
                    path: "/news/mine",
                },
            ],
        },
        {
            text: "Компания",
            icon: <MapsHomeWorkIcon />,
            children: [
                {
                    text: "Структура",
                    path: "/company/structure",
                },
                {
                    text: "Сотрудники",
                    path: "/users/all",
                },
            ],
        },
        {
            text: "Заявки",
            icon: <FactCheckIcon />,
            children: [],
            path: '/tickets'
        },
    ];

    return (
        <div>
            <div className="flex justify-between items-center px-2 py-1">
                <div
                    className="flex items-center"
                    onClick={() => {
                        domainStore.logout();
                        uiStore.reset();
                        navigate("/login");
                    }}
                >
                    <IconButton>
                        <LogoutIcon />
                    </IconButton>
                    <div>Выйти</div>
                </div>
                <div className="flex items-center gap-2">
                    {domainStore.isPortalAdmin && (
                        <button className="flex items-center gap-1" onClick={() => navigate("/admin")}>
                            <AdminPanelSettingsIcon />
                            Админ-панель
                        </button>
                    )}
                    <IconButton onClick={() => setOpen(false)}>
                        <CloseRoundedIcon />
                    </IconButton>
                </div>
            </div>
            <Divider />
            <List>
                {pages.map((el, idx) => {
                    if (el.children.length) return <CollapsibleItem el={el} key={idx} />;
                    return <SimpleItem el={el} key={idx} />;
                })}
            </List>
        </div>
    );
};

const SimpleItem = ({ el }: ItemInterface) => {
    const navigate = useNavigate();

    return (
        <div>
            <ListItemButton onClick={() => navigate(el.path as string)}>
                <ListItemIcon>{el.icon}</ListItemIcon>
                <ListItemText primary={el.text} />
            </ListItemButton>
        </div>
    );
};

const CollapsibleItem = ({ el }: ItemInterface) => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <div>
            <ListItemButton onClick={handleClick}>
                <ListItemIcon>{el.icon}</ListItemIcon>
                <ListItemText primary={el.text} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List disablePadding>
                    {el.children.map((child, lower_idx) => (
                        <ListItemButton sx={{ pl: 9 }} onClick={() => navigate(child.path)} key={lower_idx}>
                            <ListItemText primary={child.text} />
                        </ListItemButton>
                    ))}
                </List>
            </Collapse>
        </div>
    );
};

export default MyDrawer;
