import { BaseSyntheticEvent, ReactNode } from "react";
import MainLayout from "../../../../layouts/MainLayout";
import { Breadcrumbs, Link } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from "react-router-dom";

interface PathWrapperInterface {
    children: ReactNode;
}

const pathDict: any = {
    admin: "Главная",
    vacation: "Отпуска",
};

const PathWrapper = ({ children }: PathWrapperInterface) => {
    const navigate = useNavigate();
    const pathArray = window.location.pathname.slice(1).split("/");

    const handleLink = (e: BaseSyntheticEvent) => {
        e.preventDefault();
        navigate(-(pathArray.length - 1 - e.target.dataset.idx));
    };

    return (
        <MainLayout>
            <div className="avx-page-h mb-3">Админ-панель</div>
            <div className="mb-4">
                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
                    {pathArray.map((el, idx) => {
                        if (idx === pathArray.length - 1) {
                            return (
                                <div key={idx} className="avilex-color dark:dark-primary-text-500">
                                    {pathDict[el]}
                                </div>
                            );
                        }
                        return (
                            <Link
                                key={idx}
                                underline="hover"
                                color="inherit"
                                href={"/" + pathArray.slice(0, idx + 1).join("/")}
                                onClick={handleLink}
                                data-idx={idx}
                            >
                                {pathDict[el]}
                            </Link>
                        );
                    })}
                </Breadcrumbs>
            </div>
            {children}
        </MainLayout>
    );
};

export default PathWrapper;
