import { useState, useEffect } from "react";
import Store from "./store";
import UsersBlock from "../UsersBlock";

const RecentEmployeesBlock = () => {
    const [store] = useState<Store>(() => new Store());

    useEffect(() => {
        const fetchUsers = async () => {
            await store.fetchUsers();
        };
        fetchUsers();
    }, []);

    return (
        <div className="general-widget-inner">
            <div>
                <h1>Новые сотрудники</h1>
                <div></div>
            </div>
            <div>
                <UsersBlock store={store} loadingElementsNum={5} />
            </div>
        </div>
    );
};

export default RecentEmployeesBlock;
