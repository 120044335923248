import { BaseSyntheticEvent, ChangeEvent, useContext, useEffect } from "react";
import { MyCalendarContext, MyCalendarContextType } from "../../../../context";
import { Checkbox, FormControlLabel } from "@mui/material";
import ExpandIcon from "@mui/icons-material/Expand";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import "./styles.css";
import { observer } from "mobx-react-lite";

interface ToolsInterface {
    allUsersExpanded: boolean;
    setAllUsersExpanded: (a: boolean) => void;
}

const Tools = ({ allUsersExpanded, setAllUsersExpanded }: ToolsInterface) => {
    const { myCalendarStore } = useContext(MyCalendarContext) as MyCalendarContextType;

    useEffect(() => {
        myCalendarStore.handleSubordinatesChange();
    }, [myCalendarStore.onlyIntersecting, myCalendarStore.onlyNeedApprove]);

    const handleExpandToggle = (e: BaseSyntheticEvent) => {
        setAllUsersExpanded(e.target.checked);
    };

    const handleUnprocessedChange = (e: ChangeEvent<HTMLInputElement>) => {
        myCalendarStore.setOnlyNeedApprove(e.target.checked);
    };

    const handleIntersectingChange = (e: ChangeEvent<HTMLInputElement>) => {
        myCalendarStore.setOnlyIntersecting(e.target.checked);
    };

    return (
        <div className="flex justify-between items-end gap-x-3 flex-wrap">
            <div className="flex flex-col">
                <FormControlLabel
                    control={
                        <Checkbox
                            disabled={myCalendarStore.loadingSubordinates}
                            checked={myCalendarStore.onlyNeedApprove}
                            onChange={handleUnprocessedChange}
                            size="small"
                        />
                    }
                    label={<span className="tool-text-size">Только несогласованные</span>}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            disabled={myCalendarStore.loadingSubordinates}
                            checked={myCalendarStore.onlyIntersecting}
                            onChange={handleIntersectingChange}
                            size="small"
                        />
                    }
                    label={<span className="tool-text-size">Только пересекающиеся</span>}
                />
            </div>
            <FormControlLabel
                control={
                    <Checkbox
                        disabled={myCalendarStore.loadingSubordinates}
                        checked={allUsersExpanded}
                        onChange={handleExpandToggle}
                        size="small"
                        icon={<ExpandIcon />}
                        checkedIcon={
                            <UnfoldLessIcon className={`${!myCalendarStore.loadingSubordinates ? "text-white" : ""}`} />
                        }
                    />
                }
                label={<span className="tool-text-size">{allUsersExpanded ? "Свернуть" : "Показать"}</span>}
                className="!mr-0"
            />
        </div>
    );
};

export default observer(Tools);
